import React, { useEffect } from "react";

import hacker from "./img/hacker-stories2.png";
import places from "./img/places.png";
import youtube from "./img/youtube.png";
import hoursFinder from "./img/hoursFinder.png";
import weatherApp from "./img/weather-app.png";

import linkedinIcon from "./img/icons/linkedin.png";
import githubIcon from "./img/icons/github.png";
import twitterIcon from "./img/icons/twitter.png";

import "./App.css";

const App = () => {
  useEffect(() => {
    document.title = "Ki Woong Kim";
  }, []);

  return (
    <div className="App">
      <TopBar />

      <div className="Intro_name">
        <b>Hey, I'm Kiwoong -</b>
        <br />A software engineer from Northern Virginia
        <br />
        <br />
        <span className="Intro_quip">
          I'm currently a software developer at AINS. Back in May 2020 I
          graduated with a Bachelor of Computer Science from&nbsp;
          <a
            href="https://www.gmu.edu"
            target="_blank"
            rel="noreferrer noopener"
          >
            George Mason University
          </a>{" "}
          in Fairfax, Virginia.
          <br /> <br />I have a disposition for problem solving, a passion for
          programming, and a love for design. Currently, I am exploring the
          exciting world of full stack web development on the side.
          <br /> <br />
          <a href="mailto:kiwoong.kim@outlook.com">Contact me!</a>
        </span>
      </div>

      <h1 style={{ color: "", paddingLeft: "10%" }}>Featured Projects</h1>
      <Container />
      <div className="BottomBar">Thanks for checking out my website!</div>
    </div>
  );
};

function TopBar() {
  return (
    <div className="TopBar" id="myTopBar">
      <span className="Name">kiwoong kim</span>
      <span className="Links">
        <span className="top_links">
          <a
            href="https://github.com/kiwoong1128"
            target="_blank"
            rel="noreferrer noopener"
          >
            github
          </a>
        </span>
        <span className="top_links">
          <a
            href="https://www.linkedin.com/in/kiwoong"
            target="_blank"
            rel="noreferrer noopener"
          >
            linkedin
          </a>
        </span>
        <span className="top_links">
          <a
            href="https://twitter.com/kiwoong1128"
            target="_blank"
            rel="noreferrer noopener"
          >
            twitter
          </a>
        </span>
      </span>
      <span className="responsive">
        <a
          className="icon"
          href="https://github.com/kiwoong1128"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={githubIcon} alt="github logo" />
        </a>

        <a
          className="icon"
          href="https://www.linkedin.com/in/kiwoong"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={linkedinIcon} alt="linkedin logo" />
        </a>

        <a
          className="icon"
          href="https://twitter.com/kiwoong1128"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={twitterIcon} alt="linkedin logo" />
        </a>
      </span>
    </div>
  );
}

const Container = () => {
  return (
    <div className="Container">
      <a
        href="https://desolate-basin-54403.herokuapp.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Card
          imgsrc={weatherApp}
          header="Weather App"
          body="This minimalistic weather app provides you with an easy-to-read,
        concise weather forecast with a location given by the user via the input field."
          footer="Built with HTML/CSS/JSS/Handlebars along with Express.js and Node.js for handling the back-end. 
        mapbox.com and weatherstack.com APIs were used for location and weather data."
        />
      </a>
      <a
        href="https://hacker-news-cdaa6.web.app/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Card
          imgsrc={hacker}
          header="My Hacker Stories"
          body="This webpage fetches articles from Hacker News API and 
        presents it with a minimalistic design and sorting functions."
          footer="Built with React."
        />
      </a>
      <Card
        imgsrc={hoursFinder}
        header="HoursFinder"
        body="HoursFinder makes it easy for teachers at GMU to find office hours 
        that works for them and their students."
        footer="Built with Java, JDBC, Postgres, and Javalin."
      />
      <Card
        imgsrc={places}
        header="Faces & Places"
        body="An interactive application
      designed to keep track of various places visited by multiple users."
        footer="Built with React."
      />
      <Card
        imgsrc={youtube}
        header="Youtube Clone"
        body="A simple single-page youtube clone with interactive media player, 
      recommended thumbnails, and comments section."
        footer="Built with HTML/CSS/JavaScript"
      />
    </div>
  );
};

const Card = ({ imgsrc, header, body, footer }) => {
  return (
    <div className="Card">
      <img src={imgsrc} alt={header} />
      <div className="Overlay">
        <h2>{header}</h2>
        <p>{body}</p>

        <p style={{ position: "absolute", bottom: "15px" }}>{footer}</p>
      </div>
    </div>
  );
};

export default App;
